
:root {
  --text-color:#495057;
  --text-color-secondary:#6c757d;
  --primary-color:#ce2030;
  --primary-color-text:#ffffff;
}

body {
  margin: 0;
  padding:0;
  font-family: 'Raleway', sans-serif;
}
.dashingboard {
  background: #fcf4f7;
  display: flex;
  width: 100vw;
  min-height: 100vh;
}
.main-dash-content {
  width: 100%;
}
.main-content-arena {
  padding: 30px;
}

.flexme {
  display: flex;
}
.flx-50 {
  width: 50%;
}
svg {
  padding-right: 15px;
}
.sidebarMenu ul {
  list-style: none;
  padding: 0px;
}
.sidebarMenu ul li a {
  color:#fff;
  text-decoration: none;
  font-weight: 600;
}

.form-group input.form-control, .form-group textarea.form-control, select.form-control{
  margin-bottom: 20px;
  background: #f4f4f4;
  padding: 7px 10px;
  font-size: 12px;
  color: #4e4e4e;
}
input.form-control {
  padding: 5px;
  border: 1px solid;

  letter-spacing: 1px;
}
.w80{
  width: 80%;
}
.wfull{
  width: 100%;
}
.row.rws-form-g {
  width: 84%;
}
.h100f{
  height: 100px;
}
.btn {
  padding: 7px 17px;
  border: none;
  cursor: pointer;
  border-radius:0px;
}
.btn.btn-circle {
  border-radius: 15px;
  background: #ce2030;
}
.btn.btn-circle:hover {
  background: #2a3ea4;
}
.special-btn {
  background: #ce2030 !important;
  color: #fff;
  padding: 7px 0px 9px 14px;
  text-align: center;
  margin-left: 10px;
  font-size: 12px;
  border-radius: 10px;
  margin-right: 10px;
}
.special-btn:hover {
  background: #2a3ea4;
}
.fl-left{
  float:left;
}
.card {
  background: #fff;
  padding: 40px;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}
.sub-cat-container {
  padding: 0px 0px 5px 23px;
  margin-bottom: 14px;
  margin-top: -9px;
}
.sub-cat label {
  margin-left: 10px;
}
.sub-cat {
  padding: 3px;
}
.htbig {
  height: 60px;
}
.htbigger {
  height: 90px;
}
.htbiggest {
  height: 200px;
}
.img-source {
  position: absolute;
  bottom: 40%;
  left: 35%;
  z-index: 100000;
}
.hideme{
  display: none;
}
img.proc {
  width: 130px;
  position: absolute;
  bottom: 25%;
}
img.proc.profixed {

  position: fixed;
}
.card {
  background: #fff;
  padding: 40px;
  border-radius: 1px;
  border: 1px solid #f1f1f1;
}
.p-radiobutton.p-radiobutton-box:focus-visible {
  border: 2px solid #c80f02d1 !important;
}
.btngreen {
  background: #02c81ed1;
  color: #fff;
}
.btnred {
  background: #c80f02d1;
  color: #fff;
}
.alert-area {
  margin-top: 15px;
  padding: 16px;
}
.alert-warning {
  background: #c87502d1;
  color: #ffffff;
}
.alert-success {
  background: #02c868d1;
  color: #ffffff;
}
.alert-danger {
  background: #c81602d1;
  color: #ffffff;
}
.bcwd {
  width: 30px;
  font-size: 9px;
  height: 30px;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}
.center-piece{
  text-align: center;
}
input:read-only{
  font-style: italic;
  color:rgba(144, 144, 144, 0.804);
  font-weight: 700;
}
img.img-pro-admin {
  width: 60px;
}
table.pro-list tr.r-pro th {
  background: #1d322b;
  color: #fff;
  padding: 10px 10px;
}
span.bar {
  padding: 0px 6px;
}
.image-single {
  width: 50%;
}

img.img-single {
  width: 100%;
}
.txt-context {
  margin-bottom: 20px;
}
.front-lbl {
  text-align: center;
  background: #1d322b;
  color: #fff;
  padding: 8px;
}
.lbl-item {
  font-weight: 200;
  margin-top: 10px;
}
.image-context {
  margin-top: 25px;
}
.full-perc {
  width: 100%;
}
.mgtee-spacer {
  padding: 20px 0px;
}

.t-name {
  margin-top: 15px;
}
.name-details {
  background: #c4c4c46e;
  height: 50px;
  text-transform: capitalize;
  color: #ffff;
  font-weight: 700;
  width: 130px;
  margin-right: 15px;
  text-align: center;
}
img.access-image {
  width: 601px;
}
table.pro-list {
  width: 100%;
}
input.cs-search {
  padding: 14px 15px;
  margin-bottom: 10px;
  width: 95%;
  background: #f0f0f0;
  border: 0px;
  border-radius: 0px;
}
img.previewItem {
  width: 500px;
  margin-bottom: 15px;
  height: 500px;
}
a.link-nav-item-side {
  width: 100%;
  display: block;
  padding: 4px 1px;
}
.mp-block-item {
  background: #ce2030;
  margin-right: 10px;
  text-align: center;
  padding: 25px 4px 8px 4px;
}
.mp-blocks-it {
  border:1px dashed #ce2030;
  margin-right: 5px;
  text-align: center;
}
a.lk-dash {
  text-decoration: none;
  color: #474747;
}
.btn-blue {
  background: #34aaff;
  color:#fff;
  text-transform: uppercase;
  border-radius: 0px;
}
.btn-blue:hover {
  background: #237ab8 !important;
  color:#fff;
}
.btn-green {
  background: #0bea00;
  color:#fff;
  text-transform: uppercase;
  border-radius: 0px;
}
.btn-green:hover {
  background: #0cae04 !important;
  color:#fff;
}
.btn-picky {
  background-color: #ce2030;
  color:#fff;
  text-transform: uppercase;
  border-radius: 0px;
}
.btn-picky:hover {
  background-color: #80141e !important;
  color:#fff !important;
}
.bottom-foot {
  padding: 20px 0px;
}
.bottom-foot .btn-picky{
  font-size: 12px;
}
.grid-catas {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows:100px;
  padding: 10px;
}
.grid-layout {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows:290px;
  padding: 10px;
}
.grid-layout .pc-lbl.pc-image {
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat:no-repeat;
}
.grid-layout .block-item {
  border: 1px solid #eeeeee;
  margin-right: 5px;
  margin-bottom:5px;
  padding: 7px;
}
.grid-layout .pc-lbl.pc-title {
  font-size: 12px;
  text-align: center;
  height: 40px;
  line-height: 15px;
}

button.btn-form {
  padding: 6px 8px;
  font-size: 21px;
  border-radius: 50%;
  border: 0px;
  text-align: center;
  background: transparent;
  border: 1px solid gainsboro;
  cursor: pointer;
  margin-left: 5px;
}
button.btn-form svg {
  padding: 0px;
  color: #ce2030;
}
.pagi-block {
  display: flex;
}
.paginate-bar {
  display: flex;
  justify-content: space-between;
}
.lb-fact-item {
  color: #777777;
}
.weightC {
  font-weight: 600;
  color:#1d322b;
}
.dsh-profile.lg-profe {
  width: 250px;
  height: 250px;
}
.no-button-style{
  background:transparent;
  border:none;
}

button.lay-default {
  background: gainsboro;
  padding: 8px 0px 6px 12px;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 6px;
  cursor: pointer;
}

button.lay-default.selectedOptioin {
  background: #ce2030;
  color: #fff;
  
}
select.form-control {
  border: 1px solid;
  background: lightgrey !important;
}
.flexme.space-far {
  justify-content: space-between;
}
.inner-display.list-layout .block-item {
  border-bottom: 1px solid #ececec;
  padding: 5px 2px;
  display: flex;
}
.inner-display.list-layout .block-item:first-child {
  border-top: 1px solid #ececec;
}
.inner-display.list-layout {
  margin-top: 20px;
  margin-bottom: 30px;
}
.inner-display.list-layout .block-item .pc-lbl.pc-image {
  width: 70px;
  height: 60px;
  background-position: center;
  background-size: contain;
  border: none;
  background-repeat: no-repeat;
}
.inner-display.list-layout .block-item .pc-lbl{

  padding: 5px;
}
.inner-display.list-layout .block-item .pc-lbl.pc-title {
  /*width: 40%;*/
  width: 220px;
}
.inner-display.list-layout .block-item .pc-l.pc-descri{
    width: 500px;
    font-size: 12px;
}
.lbldesc {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 11px;
}
.btn-circle-st {
  background: #ce2030;
  padding: 10px;
  text-align: center;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
.btn-circle-st:hover{
  background-color: #80141e;
}
.inicons {
  padding-left: 8px;

}
input.ops-profile {
  opacity: 0;
  width: 40px;
}
.height-item{
  padding: 10px 0px;
}
.dsh-profile.lg-profe-ds{
  width: 200px;
  height:  200px;
}
li.sidebarListItem {
  padding: 6px 0px;
}
h2.lbl-title-page {
  color: #949494;
  font-size: 19px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05);
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
img.img-calls {
  width: 70%;
}

img.smallpreview {
  width: 100px;
  border: 2px solid;
  opacity: 0.7;
}
.vari-flex {
  width: 50%;
  border: 1px solid #f2f2f2;
  margin-bottom: 2px;
  margin-left: 2px;
  padding: 5px;
}

.photo-images {
  border: 1px solid #e9e9e9;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
}

.modal {
 
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  width: 70%;
}
.modal-top {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: black;
  color: #fff;
}
h4.md-spot {
  margin: 0px;
  padding: 5px;
}
.modal-main-content {
  padding: 10px;
}
.img-source-search {
  text-align: center;
}
img.search-proc {
  width: 120px;
}
.modal-content .content-images {
  width: 100%;
  min-height: 100px;
}
.minimal-search {
  padding: 15px;
  font-size: 35px;
  font-weight: 800;
  opacity: 0.6;
}
tbl-fl1{
  width:90%;
}
tr.pr-b-search .pc-lbl.pc-image {
  height:60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

th.img-head-srch {
  width: 90px;
}
td.smll-search {
  font-size: 12px;
}
.file-item-input {
  padding: 10px;
  border: 1px dotted #c1c1c1;
  width: 95%;
  margin-right: 5px;
  background: #f7f7f7;
  margin-bottom: 10px;;
}
.no-style {
  list-style: none;
}
img.box-image {
  width: 80px;
}
table.p-datatable-table {
  font-size: 12px;
}
a.cat-links {
    color: #fff;
    font-weight: 700;
    text-decoration: none;
}
.spaceshare2{
  width: 50%;
}
.spaceshare3{
  width: 33%;
}


.bs-info-job {
  border: 1px solid gray;
  border-radius: 3px;
  margin-bottom: 3px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.bs-info-job.success {
  background: #c1fbd0f0;
  color: #024815f0;
}
.bs-info-job.failer {
  background: #ffcaca;
  color: #580000;
}
.box-conain-list ul {
  list-style: none;
}
.box-conain-list {
  background: #fff;
  padding: 9px;
  margin-left: 10px;
  min-height: 100px;
}
.mong-item {
  background: #e8e8e8;
  padding: 14px;
  border-radius: 2px;
  margin-bottom: 7px;
}
.ico-title {
  width: 40px;
  text-align: center;
  height: 40px;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
}
.ico-tt-green {
  background: #a0ffa073;
  color: #00e200;
}

.ico-tt-blue {
  background: #a0fcff73;
  color: #00c8e2;
}

.ico-tt-red {
  background: #ffa0a073;
  color: #e20040;
}

.flex-mg-space {
  margin-right: 15px;
  margin-bottom: 15px;
  width: 100%;
}
img.disp-image {
  height: 170px;
}
.inner-display.list-layout img.disp-image {
  height: 40px;
}
.gray-section {
  background: #f2f2f2;
  padding: 10px;
}
.category-box.ctbox1.catshortn .cat-boxer.add-photo-rad {
  height: 730px;
}

.mglt10 {
  margin-left: 10px;
}

.mglt20 {
  margin-left: 20px;

}
.mgtop20{
  margin-top: 20px;
}
.mgtop30{
  margin-top:30px;
}
.mgtop60{
  margin-top:60px;
}
.mgtop90{
  margin-top:90px;
}
.mgbot10{
  margin-bottom:10px;
}
.mgbot20{
  margin-bottom:20px;
}
.mgbot30{
  margin-bottom:30px;
}
.mgright10 {
  margin-right: 10px;
}
.wide30 {
  width: 30%;
}
.wide50{
  width: 50%;
}
.wide70 {
  width: 70%;
}
.wide100{
  width: 100%;
}
.heightf300 {
  height: 300px;
}
.heightf150 {
  height: 150px;
}
.heightf100 {
  height: 100px;
}
.but-small button {
  font-size: 12px;
}
.pd5 {
  padding:5px;
}
.pd10 {
  padding:10px;
}
.pdtb10 {
  padding:10px 0px;
}
.trans-feed {
  padding: 10px;
  background: #fcf4ff;
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 200;
}
.trans-feed.red span.ic-feed {
  color: #c50000;
}
.trans-feed.green span.ic-feed {
  color: #00c55c;
}
button.btn.btn-picky.btn-side-search {
  padding: 8px 5px 5px 17px;
  height: 38px;
}
input.form-control.search-pers {
  width: 81%;
  padding: 9px;
}
input.form-control.search-pers2 {
  width: 91%;
  padding: 9px;
}
.p-datatable img.product-image {
  height: 100px;
  width: auto;
}
ul.no-list-style {
  list-style: none;
  padding: 0px;
}
li.list-item-block.srch-list-unfound {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 7px;
  margin-bottom: 5px;
  border: 1px solid #d0d0d0;
}
.sm.profile-src {
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
}
li .image-profile {
  padding: 5px;
}
.search-block {
  padding: 13px;
}
.link-srch-button .btn {
  padding: 8px 14px;
}
li .content-profile .titling {
  padding: 4px;
  width: 150px;
  font-weight: 600;
}
button.btn.btn-green-outline {
  color: #34a814;
  background: #fff;
  border: 1px solid #34a814;
}
button.btn.btn-green-outline:hover {
  color: #fff;
  background-color: #34a814;
  border:1px solid #34a814;
}
.btn-red-outline {
  border: 1px solid #d00000;
  background: #fff;
}
.btn-red-outline:hover {
  color:#fff;
  background: #d00000;
}
.btsm1 {
  font-size: 9px !important;
}
.pro-small {
  font-size: 10px;
  font-weight: 100;
  padding: 2px;
}
.no-pad{
  padding:0px !important;
}
.right70perc  {
  width: 70%;
  margin-left: 10px;
}
.sec-info-head {
  background: #fffaf9;
}
.pd25 {
  padding: 25px;
}
textarea {
  border: 1px solid black;
}
.minh90 {
  min-height: 80px;
}
p.token-display {
  background: #e6e6e6;
  font-weight: 500;
  padding: 15px;
}
a.link-nav-item-top {
  color: #ce2030;
}

img.profile-image {
  width: 35px;
    height: 100%;
}
.img-profile-container{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
}
.card-box-block {
  padding: 3px;
  border: 1px solid;
}
.card-body.widget-body {
  padding: 10px;
}
.card-body.widget-body h3.hdi-title {
  height: 75px;
}
.profile-quick-edit input:read-only, .profile-quick-edit textarea:read-only  {
  background-color: rgb(235 208 208);
}
.mp-outer-item {
  padding: 10px;
  margin-bottom: 10px;
}
.mp-inner-item {
  background: #ce2030;
  padding: 10px;
}
.mp-blocks-it {
  border: 1px dashed #ffffff;
  min-height: 100px;
  text-align: center;
}

input.form-control.opcifileinput {
  width: 0px;
  height: 0px;
  padding: 0px;
  color: #fff;
  margin: 0px;
}
button.btn-nostyle {
  background: none;
  border: 0px;
}
.importclick {
  padding: 14px 4px 10px 15px;
  background: #f2f2f2;
  text-align: center;
  display: block;
  border: 1px dashed;
  position: absolute;
}
.rl {
  font-weight: 100;
  font-size: 12px;
}
.capital {
  text-transform: capitalize; 
}
.italisizer {
  font-weight: 600;
  font-style: italic;
}
img.sizeex {
  width: 350px;
}
img.fl-image {
  height: 230px;
}
.search-sup {
  padding: 15px;
}
.pr-image {
  border: 1px solid #f4f4f4;
  padding: 10px;
  margin-bottom: 25px;
}
.item-product-sct {
  padding: 15px 5px;
  border-top: 1px solid #d5d4d4;
}
.status-count {
  font-size: 20px;
  padding: 10px;
}
.inner-acc-block {
  background: #fcf4f7;
  padding: 13px;
  border-radius: 6px;
}

.sub-text-inf {
  font-size: 13px;
  font-weight: 400;
}
img.small-pr {
  width: 50px;
}
.butt-inner {
  height: 29px;
}
.logo-mobile{
  display: none;
}
.inner-display.list-layout .pc-lbl.pc-barcode{
  width: 150px;
}
.add-thumb {
  color: #fff;
  padding-top: 12px;
  padding-left: 15px;
}
.out-sp-success {
  width: 50px;
  height: 50px;
  background: #1de848b5;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}
.scroll-short {
  background: white;
  padding: 7px;
  border: 1px dotted;
  height: 200px;
  overflow: scroll;
}
.scroll-mid {
  background: white;
  padding: 7px;
  border: 1px dotted;
  height: 450px;
  overflow: scroll;
}
.scroll-larger {
  background: white;
  padding: 10px;
  border: 1px dotted;
  height: 700px;
  overflow: scroll;
}
img.small-stand-image {
  width: 100px;
}
.cellSmall1{
  font-size: 12px;
}
.cellSmall2{
  font-size: 10px;
}
.am-Number{
  font-size: 30px;
  font-weight: 700;
}
.tbl-sm td {
  font-size: 10px;
}
span.p-column-title {
  font-size: 12px;
}
.text-white {
  color:#fff;
}
 
span.flright {
  float: right;
}
.no-bullet {
  list-style: none;
  padding: 0px;
}
.list-blow {
  padding: 30px;
  background: #fafafa;
  height: 350px;
  overflow: overlay;
}
.tpmin5 {
  margin-top: -5px;
}
.cat-box li.ln-item {
  border: 1px solid #e4e4e4;
  padding: 10px;
  margin-bottom: 10px;
  background: #fef8f8;
  font-weight: 800;
}
ul.cat-sub li {
  background: white;
  border: 1px solid #efefef;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
}
.title-up {
  font-size: 17px;
  padding: 5px 0px;
}
.block-count {
  padding: 7px 10px;
  font-weight: 300;
}
.block-wide-a {
  width: 78%;
}
td.left-pho-item {
  width: 160px;
}
td.width30{
  width: 30px;
}
.cat-boxer.add-photo-rad {
  background: #f7f7f7;
  padding: 10px;
  height: 1000px;
  margin-bottom: 30px;
  overflow-x: scroll;
  position: absolute;
  width: 40%;
}
.main-cat-item .field-radiobutton.main-cat label {
  font-weight: 600;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus{
  box-shadow:0 0 0 0.2rem #ce2031af;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #ce2030 !important;
  background: #ce2030 !important;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #ce2030;
  background: #e39a9a;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #6366F1;
  background: #6366F1;
}
.p-radiobutton:active {
  background: #e39a9a;
}
.block-wide-a.wideonefalse {
  width: 72%;
}
.special-size {
  font-size: 30px;
  font-weight: 600;
  padding: 10px 20px;
  display: block;
  width: 45%;
  border: 2px dotted;
  margin-top: 11px;
}
.select-photo-rad {
  background: #f8f8f8;
  padding: 10px;
}
.cat-side-block {
  position: absolute;
  right: 25px;
  top: 100px;
}
.cat-sub .block-wide-a {
  width: 95%;
}
.img-rad {
  width: 40px;
  height: 40px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 6px;
  border-radius: 50%;
  border: 1px solid #dadada;
}
li.list-item {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  margin-bottom: 7px;
  background-color: #fff;
}
.add--rad-listing {
  background: #f7f7f7;
  padding: 10px;
  height:700px;
  margin-bottom: 30px;
  overflow-x: scroll;

}
.alert-source-title {
  margin-right: 15px;
  padding: 8px;
}

.td-lister.success {
  background-color: #e7ffe7;
  padding: 10px;
}
.td-lister.present {
  background-color: #e7fbff;
  padding: 10px;
}
.pr-item {
  background-color: #e5e5e5;
  padding: 10px;
}
.pr-item span {
  margin-right: 10px;
}
img.prevban {
  width: 40%;
  opacity: 0.7;
}
img.banner-itme {
  width: 100%;
  margin-bottom: 30px;
}
.p-inputtext:enabled:focus {
  border-color: #ce2030;
  box-shadow: 0 0 0 0.2rem #ce2030bd;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #fcf4f7;
  border-color: #fcf4f7;
}
img.box-image {
  height: 80px;
}
table.p-datatable-table {
  font-size: 12px;
}
.title-dash-pro {
  text-align: center;
  font-weight: 100;
}
.btn:first-child:active, :not(.btn-check) + .btn:active {
  background-color: #c8c6c6 !important;
}
