.log-start {
    width: 45%;
    height: 100vh;
   
}

.flexlog {
    justify-content: space-between;
}
.logo-base{
    background: #fcf4f7;
    display: flex;
}
.log-image-pot {
    width: 55%;
    background-size: cover;
}
.lgin-spot {
    background: white;
    width: 80%;
    margin: 25% auto 0px;
    padding: 75px 20px;
    border-radius: 10px;
    transition: 0.4s;
}

input.form-control,  textarea.form-control, select.form-control{
    margin-bottom: 20px;
    background: #f4f4f4;
    padding: 7px 10px;
    font-size: 12px;
    color: #4e4e4e;
}
.frg {
    padding-top: 10px;
}
.frg a{
    color:#ce2030;
    text-decoration: none;
    font-size: 12px;
}
.text-form {
    margin-bottom: 5px;
}
button.btn.btn-redish {
    background: #ce2030;
    color: #fff;
}

button.btn.btn-redish:hover {
    background: #a21925;
    color: #fff;
}
.wide100{
    width: 100%;
}
.mgtopMin35{
    margin-top: -35px;
}
.flright{
   float: right;
}
.lgin-spot.sign-blocktrue {
    margin: 10% auto 0px;    
}
.switch-container {
    position: relative;
}
.pos-check{
    position: absolute;
    left: -9999px;
    top: -9999px;
}
.slider-switch{
    display: flex;
    cursor: pointer;
    width: 50px;
    height: 25px;
    border-radius: 100px;
    background-color: #bfbfbf;
    position: relative;
    transition: background-color 0.2s;
}
span.slider-switch.checktrue{
   background-color: #ce2030;
}
span.slider-switch.checktrue:before{
    left: 27px;
 }
 .smround {
    border-radius: 50%;
    padding: 6px 15px;
}
.slider-switch:before{
    content: "";
    position: absolute;
    top:2px;
    left: 2px;
    width: 21px;
    height: 21px;
    border-radius: 21px;
    transition: 0.2s;
    background:#fff;
    box-shadow: 0 2px 4px;
}
.small-error-text {
    font-size: 11px;
    color: #ce2030;
}
.pdtop10{
    padding-top: 10px;
}

@media screen and (max-width: 992px) {
    .lgin-spot {
        width: 70%;
        position: absolute;
        left: 9%;
    }
}
@media screen and (max-width: 499px) {
    .lgin-spot {
        width: 90%;
        left: 5%;
    }
}