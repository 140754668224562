@media screen and (max-width: 1350px) {
    .content-nav {
       margin-right:6%; 
    }
}
@media screen and (max-width: 1250px) {
    li .content-profile .titling {
        font-size: 15px;
        width: 140px;
    }

    .inner-display.grid-layout img.disp-image {
        height: 100px;
    }
    .inner-display.grid-layout .pc-lbl.pc-image {
        height: 120px;
    }
    .grid-layout {
        grid-auto-rows: 205px;
    }
}
@media screen and (max-width: 1099px) {
    .flex-mg-space {
        width: 330px;
    }
    .grid-layout {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media screen and (max-width: 1000px) {
    .main-dash-content {
        width: 78%;
    }
}
@media screen and (max-width: 950px) {
    .grid-layout {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 760px) {
    span.name-Item {
        display: none;
    }
    .bs-side.full-side{
        width: 30px;
    }
    .main-dash-content {
        width: 94%;
    }
    .flexme.flexon-mobile {
        display: block;
    }
    img.sizeex {
        width: 250px;
    }
    .logo-spark{
        display: none;
    }
    .logo-mobile{
        display: block;
    }
    .grid-layout {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 500px) {
    .grid-layout {
        grid-template-columns: repeat(1, 1fr);
    }
}