.tab-dashboard-item {
    display: flex;
}

.side-dash-settings-nav {
    width: 20%;
    background: #fffaf9;
}

.side-dash-settings-nav .tabs {
    padding: 10px;
    color: #4b4b4b;
    cursor: pointer;
    font-size: 13px;
}
.tabs.active-tabs {
    background: #eae1e1;
}
.tab-content-box .content-tab {
    padding: 30px;
    background: #fff;
    display: none;
    font-size: 12px;
}
.tab-content-box .content-tab.content-active {
    display: block;
}
.tab-content-box {
    width: 80%;
    background: #fff;
}
.copy-box h4 {
    color: #ce2030;
}
code {
    border: 3px solid #444444;
    padding: 5px 10px;
    font-size: 10px;
}
.copy-box {
    padding: 15px 0px;
}
.total-ct {
    padding: 10px;
    font-weight: 600;
}
img.proc.mgimageup{
    bottom: 40%;
}